import React, { Suspense, useEffect, useState, createContext } from "react";

// import "./App.css";
import "./assets/css/loader.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { setCultures } from "./redux/common/cultureSlice";
import { setRefreshToken, setResourceKey, setUserCurrency } from "./services/common";
import {
  setCultureSession,
  getSessionExpiry,
} from "./services/common";

import { Routes, Route } from "react-router-dom";
import InnerBanner from "./components/common/inner_banner";
//sports starts
import { getSportsSearchParams } from "./redux/sports/sportsSearch";
import { getSportsDetails } from "./redux/sports/sportsDetails";
import { useDispatch, useSelector } from "react-redux";
import { getFlightSearchParams, setFlightSearchData } from "./redux/flight/flightSearch";
import { currencyActions, getCurrencyinfo } from "./redux/commonSlice";
import { getCountryInfo } from "./redux/common/countrySlice";
import { getSelectedFlightData } from "./redux/flight/flightSelectedSlice";
import { getSportsSeatData } from "./redux/sports/sportsPayment";
import { getTicketData } from "./redux/ticketSlice";
import { useSearchParams } from "react-router-dom";
import { getLoginDetails, getUserData } from "./redux/loginSlice";
import { newSearchReq } from "./services/flight/searchServices";
import SeoPageThree from "./components/seo/SeoPageThree";
import moment from "moment";
import { getCouponCodeData } from "./redux/flight/couponCodeSlice";
import { getSelectedSportsData } from "./redux/sports/selectedSportsSlice";
import { getSelectedAncillaries } from "./redux/flight/getSelectedAncillaryDetails";


const EnTheme = React.lazy(() => import("./EnTheme"));
const ArTheme = React.lazy(() => import("./ArTheme"));
const Header = React.lazy(() => import("./components/common/header"));
const Footer = React.lazy(() => import("./components/common/footer"))
const Home = React.lazy(() => import("./components/home"))
const FlightResult = React.lazy(() => import("./components/flight/flight_result/flight_result"))
const WegoSearch = React.lazy(() => import("./components/flight/wego/WegoSearch"))
const WegoBrandedFare = React.lazy(() => import("./components/flight/wego/WegoBrandedFare"))
const FlightPax = React.lazy(() => import("./components/flight/flight_pax/flight_pax"))
const SportsResult = React.lazy(() => import("./components/sports/sports_result/sports_result"))
const SportsDetails = React.lazy(() => import("./components/sports/sports_details/sports_details"))
const SportsPax = React.lazy(() => import("./components/sports/sports_pax/sports_pax"))
const PaymentProgress = React.lazy(() => import("./components/flight/flightPayment/payment_progress"))
const PaymentFailed = React.lazy(() => import("./components/flight/flightPayment/payment_failed"))
const BookingFailed = React.lazy(() => import("./components/flight/flightPayment/bookingFailed"))
const SportsTicket = React.lazy(() => import("./components/Ticket/sports_ticket"))
const ManageMyBooking = React.lazy(() => import("./components/mybooking/ManageBooking"))
const MyProfile = React.lazy(() => import("./components/mybooking/MyProfile/MyProfile"))
const MyBookingAccount = React.lazy(() => import("./components/mybooking/MyBookingAccount"))
const MyBookingWithoutLogin = React.lazy(() => import("./components/mybooking/ReffLogin/MyBookingWithoutLogin"))
const MyBookingCancel = React.lazy(() => import("./components/mybooking/Common/MyBookingCancel"))
const ResetPassword = React.lazy(() => import("./components/common/ResetPassword"))
const FLightBookingOnly = React.lazy(() => import("./components/flight/flightPayment/flight_booking_only"))
const TicketBooked = React.lazy(() => import("./components/flight/flightPayment/ticketBooked"))
const FlightTicket = React.lazy(() => import("./components/FlightTicket"))
const CMSPages = React.lazy(() => import("./components/common/CMSPages"))
const SpecialOfferDetails = React.lazy(() => import("./components/specialoffer/SpecialOfferDetails"))
const DirectPayment = React.lazy(() => import("./components/common/DirectPayment"))
const DirectPaymentSuccess = React.lazy(() => import("./components/common/DirectPaymentSuccess"))
const Unauthorized = React.lazy(() => import("./components/common/Unauthorized"))
const SeoAllAirline = React.lazy(() => import("./components/seo/SeoAllAirline"))
const SeoSelectedAirline = React.lazy(() => import("./components/seo/SeoSelectedAirline"))

const url_segment = process.env.REACT_APP_BASE_URL.replace("/", "");
let current_url = "";
if (url_segment != "")
  current_url = window.location.href.replace(url_segment, "");
else current_url = window.location.href;

export const outsideContext = createContext();
function Paths() {
  const navigate = useNavigate();
  const [isLoadAr, setIsLoadAr] = useState(false);
  const culture = useSelector((state) => state.culture.currentCulture);
  moment.locale(culture);
  sessionStorage.setItem("culture", culture);

  const dispatch = useDispatch();

  const currency = useSelector(
    (state) => state.currencyDetails.currentCurrency
  );
  const currencyCountry = useSelector(
    (state) => state.currencyDetails.currencyCountry
  );
  const flightSearchStatus = useSelector((state) => state.flightSearch.loading);
  const flightSearchData = useSelector(
    (state) => state.flightSearch.flightSearch
  );
  const sportsSearchStatus = useSelector((state) => state.sportsSearch.loading);
  const sportsDetailsStatus = useSelector(
    (state) => state.sportsDetails.loading
  );
  const currencySearchStatus = useSelector(
    (state) => state.currencyDetails.loading
  );
  const selectedFlightStatus = useSelector(
    (state) => state.selectedFlight.loading
  );
  const countrySearchStatus = useSelector(
    (state) => state.countryDetails.loading
  );

  const loginDetails = useSelector((state) => state.loginDetail.loginDetail);
  const userData = useSelector((state) => state.loginDetail.userData);
  const sportsSeatStatus = useSelector((state) => state.sportsPayment.loading);
  const ticketDataStatus = useSelector((state) => state.ticketData.loading);
  const cultureStatus = useSelector((state) => state.culture.loading);
  const [searchQueryParams, setSearchQueryParams] = useSearchParams();
  const [currencyMenuOpen, setCurrencyMenuOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [mobMenuOpen, setMobMenuOpen] = useState(false);

  let currentSearchID = searchQueryParams.get("SearchId");

  const isReviewPage =
    current_url.split("/")[5]?.split("?")[0] == "FlightReview";

  const [menuTab, setMenuTab] = useState("flightSearch");
  const setMenuTabHandler = (params) => {
    setMenuTab(params);
  };
  sessionStorage.setItem("culture", culture);
  const CHOSEN_THEME = culture;


  function useOutsideAlerter(ref1, ref2, type, funct, module = "") {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (type == "pax" || type == "sort") {
          if (ref1.current && !ref1.current.contains(event.target)) {
            funct(false);
            return;
          }
        }
        if (type == "baggagePopup") {
          funct(false);
        }
        if (type == "wallet") {
          if (ref1.current && !ref1.current.contains(event.target)) {
            funct(false);
            return;
          }
        }
        if (type == "country") {
          if (ref1.current && !ref1.current.contains(event.target)) {
            funct(false);
            return;
          }
        }
        if (module == "currencyAndProfile") {
          if (ref1.current && !ref1.current.contains(event.target)) {
            setCurrencyMenuOpen(false);
          }
          if (ref2?.current && !ref2?.current.contains(event.target)) {
            setProfileOpen(false);
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref1, ref2]);
  }

  useEffect(() => {
    let refreshTokenTimeOut;
    async function fetch() {
      try {
        // Retrieve cultureType and setValue from sessionStorage


        // Determine the language based on the current URL or default from environment variable
        let lang;
        if (current_url.split("/")[3] === "en") {
          lang = "en";
        } else if (current_url.split("/")[3] === "ar") {
          lang = "ar";
        } else {
          lang = process.env.REACT_APP_CULTURE;
        }

        const [resource, cultureSessionResponse] = await Promise.all([
          setResourceKey(),
          setCultureSession({ lang })
        ]);
        // Ensure both promises resolved successfully before proceeding
        if (resource && cultureSessionResponse) {
          // Fetch culture data
          const cultureResponse = cultureSessionResponse;

          if (cultureResponse) {
            dispatch(setCultures(cultureResponse.data));
            const LOCAL_STORAGE_KEYS = { en: "languageen", ar: "languagear" };
            const { data } = cultureResponse;

            localStorage.setItem(LOCAL_STORAGE_KEYS[data.culture], JSON.stringify(cultureResponse.data.lang));
            document.title = cultureResponse.data.lang.tathkarah_title;
            if (cultureResponse.data.culture === "ar") {
              setIsLoadAr(true);
            }
          }
        }
        dispatch(getCountryInfo());
        dispatch(getCurrencyinfo());
        dispatch(getLoginDetails());
        dispatch(getUserData());

      } catch (error) {
        console.error("Error occurred while setting culture session:", error);
      }
      // dispatch(getDeals());
      if (
        (current_url.split("/")[5] &&
          current_url.split("/")[5]?.split("?")[0] == "MyBooking") ||
        (current_url.split("/")[4] &&
          current_url.split("/")[5]?.split("?")[0] == "MyBooking")
      ) {
        dispatch(getTicketData(navigate));
      }

      if (
        current_url.split("/")[4] &&
        current_url.split("/")[4].split("?")[0] == "SportsResult"
      ) {
        dispatch(getSportsSearchParams(navigate));
      }
      if (
        current_url.split("/")[4] &&
        current_url.split("/")[4].split("?")[0] == "SportsDetails"
      ) {
        dispatch(getSportsSearchParams(navigate));
        dispatch(getSportsDetails(navigate));
      }

      
      if (current_url.split("/")[4] == "FlightResult" || current_url.split("/")[4].split('?')[0] == "WegoBrandedFare")
        dispatch(getFlightSearchParams(currentSearchID, navigate));
      if (current_url.split(/[/?]/)[4] == "Flight") {
        dispatch(getFlightSearchParams(currentSearchID, navigate));
        dispatch(getSelectedFlightData(currentSearchID, navigate));
        dispatch(getCouponCodeData(currentSearchID, navigate));
        dispatch(getSelectedAncillaries(currentSearchID, navigate));
      }
      if (current_url.split("/")[4] && current_url.split("/")[4] == "Sports") {
        dispatch(getCountryInfo(navigate));
        dispatch(getSportsDetails(navigate));
        dispatch(getSportsSeatData(navigate));
        dispatch(getSportsSearchParams(navigate));
        dispatch(getSelectedSportsData(currentSearchID, navigate));
        dispatch(getCouponCodeData(navigate));
      }


      if (
        (current_url.split("/")[4] &&
          current_url.split("/")[4]?.split("?")[0] == "SportsTicket") ||
        (current_url.split("/")[3] &&
          current_url.split("/")[4]?.split("?")[0] == "FlightTicket")
      ) {
        dispatch(getTicketData(navigate));
      }
    }

    async function initRefreshSession(loadAll = true) {
      const tokenExpiryResponce = await getSessionExpiry();
      if (tokenExpiryResponce) {
        refreshTokenTimeOut = setTimeout(async () => {
          const responce = setRefreshToken();
          if (responce) {
            initRefreshSession(false);
          }
        }, tokenExpiryResponce);
        if (loadAll) fetch();
      }
    }
    initRefreshSession();

    return () => {
      clearTimeout(refreshTokenTimeOut);
    };
  }, []);;

  useEffect(() => {
    //Setting B2b currency
    if (loginDetails?.Role == "Admin" && userData?.AgencyCurrency) {
      (async () => {
        let responce = await setUserCurrency(userData?.AgencyCurrency, currencyCountry, "path");
        if (responce) {
          dispatch(currencyActions.setCurrency({ currencyCode: userData?.AgencyCurrency, currencyCountry }));
        }
      })();
    }
  }, [userData, loginDetails])

  if (
    (flightSearchStatus && current_url.split("/")[4] == "FlightResult") ||

    (current_url.split(/[/?]/)[4] == "Flight" && selectedFlightStatus) ||
    (!flightSearchStatus &&
      flightSearchData === "" &&
      current_url.split("/")[4] == "FlightResult")
  ) {
    

    if (!flightSearchStatus && flightSearchData === "") {
      // alert("newsearchReq");
      let searchUrl = window.location.href.split("FlightResult/")[1];
      newSearchReq(searchUrl, currency, dispatch, currentSearchID, currencyCountry);
    }
    setFlightSearchData()
    return (
      <div className="openLoader">
      <div className="loader-lg">
        <div className="loader-open-dot"></div>
        <div className="loader-open-dot"></div>
        <div className="loader-open-dot"></div>
        <div className="loader-open-dot"></div>
        <div className="loader-open-dot"></div>
        <div className="loader-open-dot"></div>
      </div>
    </div>
    );
  } else if (
    (sportsSearchStatus &&
      current_url.split("/")[4] &&
      current_url.split("/")[4].split("?")[0] == "SportsResult")
  ) {
    
    return (
      <div className="openLoader">
        <div className="loader-lg">
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
        </div>
      </div>
    );
  } else if (
    (sportsDetailsStatus &&
      current_url.split("/")[4] &&
      current_url.split("/")[4].split("?")[0] == "SportsDetails") 
  ) {
    
    return (
      <div className="openLoader">
        <div className="loader-lg">
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
        </div>
      </div>
    );
  } else if (
    (countrySearchStatus ||
      sportsDetailsStatus ||
      sportsSeatStatus ||
      sportsSearchStatus) &&
    current_url.split("/")[4] &&
    current_url.split("/")[4].split("?")[0] == "SportsPaxPayment"
  ) {
    
    return (
      <div className="openLoader">
        <div className="loader-lg">
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
        </div>
      </div>
    );
  } 
  else if (
    ticketDataStatus &&
    ((current_url.split("/")[4] &&
      current_url.split("/")[4].split("?")[0] == "SportsTicket") ||
      (current_url.split("/")[4] &&
        current_url.split("/")[4].split("?")[0] == "FlightTicket"))
  ) {
    

    return (
      <div className="openLoader">
        <div className="loader-lg">
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
        </div>
      </div>
    );
  } else if (cultureStatus) {
    
    return (
      <div className="openLoader">
        <div className="loader-lg">
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
          <div className="loader-open-dot"></div>
        </div>
      </div>
    );
  }  else {
    return (
      <outsideContext.Provider
        value={{ useOutsideAlerter, mobMenuOpen, setMobMenuOpen }}
      >
        <Suspense fallback={<>
        
          <div className="openLoader">
            <div className="loader-lg">
              <div className="loader-open-dot"></div>
              <div className="loader-open-dot"></div>
              <div className="loader-open-dot"></div>
              <div className="loader-open-dot"></div>
              <div className="loader-open-dot"></div>
              <div className="loader-open-dot"></div>
            </div>
          </div>
        </>}>
          {CHOSEN_THEME == "en" && <EnTheme />}
          {CHOSEN_THEME == "ar" && <ArTheme />}
          <div>
            <Routes>
              {culture && (
                <Route
                  path="*"
                  element={
                    <Header
                      setMenuTab={setMenuTabHandler}
                      showsearchPanel={menuTab}
                      useOutsideAlerter={useOutsideAlerter}
                      currencyMenuOpen={currencyMenuOpen}
                      setCurrencyMenuOpen={setCurrencyMenuOpen}
                      profileOpen={profileOpen}
                      setProfileOpen={setProfileOpen}
                    />
                  }
                />
              )}
            </Routes>
            <Routes>
              {culture && (
                <Route
                  exact
                  path={process.env.REACT_APP_BASE_URL}
                  element={
                    <Home
                      setMenuTab={setMenuTabHandler}
                      showsearchPanel={menuTab}
                    />
                  }
                />
              )}

              <Route
                path={process.env.REACT_APP_BASE_URL + ":culture"}
                element={
                  <Home
                    setMenuTab={setMenuTabHandler}
                    showsearchPanel={menuTab}
                  />
                }
              />
              {/*flight routes start*/}
              <Route
                exact
                path={
                  process.env.REACT_APP_BASE_URL +
                  ":culture/FlightResult/:tripType/:route/:pax/:class/:isDirect/:prefAirline/:allAirport"
                }
                element={<FlightResult />}
              />
              {/* WEGO SEARCH FLIGHT*/}
              <Route
                path={process.env.REACT_APP_BASE_URL + ":culture/MetaSearch"}
                element={<WegoSearch />}
              />
              <Route
                path={process.env.REACT_APP_BASE_URL + ":culture/WegoBrandedFare"}
                element={<WegoBrandedFare />}
              />

              <Route
                path={process.env.REACT_APP_BASE_URL + ":culture/Flight/*"}
                element={<FlightPax isReviewPage={isReviewPage} />}
              />
              {/* sports routs starts */}
              <Route
                path={process.env.REACT_APP_BASE_URL + ":culture/SportsResult"}
                element={<SportsResult />}
              />
              <Route
                exact
                path={process.env.REACT_APP_BASE_URL + ":culture/SportsDetails"}
                element={<SportsDetails />}
              />
              {/*  <Route
            exact
            path={process.env.REACT_APP_BASE_URL + ":culture/SportsPaxPayment"}
            element={<SportsPax />}
          /> */}
              <Route
                exact
                path={process.env.REACT_APP_BASE_URL + ":culture/Sports/*"}
                element={<SportsPax />}
              />
              <Route
                exact
                path={
                  process.env.REACT_APP_BASE_URL + ":culture/PaymentProgress"
                }
                element={<PaymentProgress />}
              ></Route>

              <Route
                exact
                path={process.env.REACT_APP_BASE_URL + ":culture/PaymentFailed"}
                element={<PaymentFailed />}
              ></Route>

              <Route
                exact
                path={
                  process.env.REACT_APP_BASE_URL + ":culture/TicketingFailed"
                }
                element={<BookingFailed />}
              ></Route>

              <Route
                path={process.env.REACT_APP_BASE_URL + ":culture/SportsTicket"}
                element={<SportsTicket />}
              />
            </Routes>
            {/*   Mybooking Section   */}

            <Routes>
              <Route
                exact
                path={
                  process.env.REACT_APP_BASE_URL +
                  ":culture/Mybooking/ManageBooking"
                }
                element={<ManageMyBooking />}
              />
              <Route
                exact
                path={process.env.REACT_APP_BASE_URL + ":culture/MyProfile"}
                element={<MyProfile />}
              />
              <Route
                exact
                path={process.env.REACT_APP_BASE_URL + ":culture/MybookingForm"}
                element={<MyBookingAccount />}
              />
              <Route
                exact
                path={
                  process.env.REACT_APP_BASE_URL +
                  ":culture/MybookingForm/MyBooking"
                }
                element={<MyBookingWithoutLogin />}
              />
              <Route
                exact
                path={
                  process.env.REACT_APP_BASE_URL +
                  ":culture/MybookingCancelFromMail"
                }
                element={<MyBookingCancel />}
              />
              <Route
                path={
                  process.env.REACT_APP_BASE_URL + ":culture/ChangePassword"
                }
                element={<ResetPassword />}
              />

              <Route
                exact
                path={process.env.REACT_APP_BASE_URL + ":culture/BookingFailed"}
                element={<BookingFailed />}
              ></Route>
              <Route
                exact
                path={
                  process.env.REACT_APP_BASE_URL + ":culture/BookingSuccess"
                }
                element={<FLightBookingOnly />}
              ></Route>
              <Route
                exact
                path={process.env.REACT_APP_BASE_URL + ":culture/TicketBooked"}
                element={<TicketBooked />}
              ></Route>
              <Route
                path={process.env.REACT_APP_BASE_URL + ":culture/FlightTicket"}
                element={<FlightTicket />}
              />
              <Route
                path={process.env.REACT_APP_BASE_URL + ":culture/AboutUs"}
                element={<CMSPages />}
              />
              <Route
                path={process.env.REACT_APP_BASE_URL + ":culture/ContactUS"}
                element={<CMSPages />}
              />
              <Route
                path={process.env.REACT_APP_BASE_URL + ":culture/Terms"}
                element={<CMSPages />}
              />
              <Route
                path={process.env.REACT_APP_BASE_URL + ":culture/SiteMap"}
                element={<CMSPages />}
              />
              <Route
                path={process.env.REACT_APP_BASE_URL + ":culture/PrivacyPolicy"}
                element={<CMSPages />}
              />
              <Route
                path={process.env.REACT_APP_BASE_URL + ":culture/seo"}
                element={<SeoPageThree />}
              />
              <Route
                path={
                  process.env.REACT_APP_BASE_URL +
                  ":culture/SpecialOfferDetails"
                }
                element={<SpecialOfferDetails />}
              />
              <Route
                path={process.env.REACT_APP_BASE_URL + ":culture/DirectPayment"}
                element={<DirectPayment />}
              />
              <Route
                path={
                  process.env.REACT_APP_BASE_URL +
                  ":culture/DirectPayment/paymentSuccess"
                }
                element={<DirectPaymentSuccess />}
              />
              <Route
                path={
                  process.env.REACT_APP_BASE_URL +
                  "/unauthorized"
                }
                element={<Unauthorized />}
              />
              <Route
                path={
                  process.env.REACT_APP_BASE_URL +
                  ":culture/AllAirlines"
                }
                element={<SeoAllAirline />}
              />
              <Route
                path={
                  process.env.REACT_APP_BASE_URL +
                  ":culture/AllAirlines/:airlineCode"
                }
                element={<SeoSelectedAirline />}
              />
            </Routes>
            <Routes>
              {culture && <Route path="*" element={<Footer />} />}
            </Routes>
          </div>
        </Suspense>
      </outsideContext.Provider>
    );
  }
}
export default Paths;
