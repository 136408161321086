import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  assembleCountrys,
  getCurrency,
  getIpSettings,
} from "../services/common";

const getCurrencyinfo = createAsyncThunk("api/currencyEn", async () => {
  try {
    // Perform multiple asynchronous operations concurrently
    const ipSettingsResponce = await getIpSettings()
    
    const [currencyResult, countryResponse] = await Promise.all([
      getCurrency(),
      assembleCountrys(true)
    ]);
    let userCountry = currencyResult.Data.userCountry;

    if (countryResponse && ipSettingsResponce) {
      let dataCountry = countryResponse.Data.find((country) => {
        if (userCountry) {
          return country.Code == userCountry;
        } else {
          return country.Code == ipSettingsResponce.Data.CurrencyCode;
        }
      });

      if (dataCountry === undefined) {
        dataCountry = countryResponse.Data.find((country) => {
          return country.Code == process.env.REACT_APP_COUNTRY_CODE;
        });
        currencyResult.Data.userCountry = dataCountry.Code;
      }

      currencyResult.Data.currencyCountry = dataCountry.Code;
      currencyResult.Data.currentCurrency = ipSettingsResponce.Data.CurrencyCode != null ? ipSettingsResponce.Data.CurrencyCode : "USD";
      currencyResult.Data.currentCountryName = dataCountry.Name;
      currencyResult.Data.displayCountryList = countryResponse.Data;
    }

    if (currencyResult) {
      return currencyResult.Data;
    }
  } catch (error) {
    // Handle any errors that occurred during the asynchronous operations
    console.error("An error occurred during currency info retrieval:", error);
    throw error;
  }
});

const currencySlice = createSlice({
  name: "currency",
  initialState: {
    data: [],
    defaultCurrency: "KWD",
    error: "",
    loading: true,
    currentCurrency: "KWD",
    myBookingCurrency: null,
    currencyCountry: "KW",
    currencyCountryName: { en: "KUWAIT", ar: "KUWAIT" },
    displayCountryList: [],
  },
  reducers: {
    setCurrency: (state, action) => {
      state.currentCurrency = action.payload.currencyCode;
      if (action.payload.countryCode) {
        state.currencyCountry = action.payload.countryCode;
        state.currencyCountryName = action.payload.countryName;
      }
    },
    setMyBookingCurrency: (state, action) => {
      state.myBookingCurrency = action.payload;
    },
  },
  extraReducers: {
    [getCurrencyinfo.fulfilled]: (state, action) => {
      console.log("payload", action.payload);
      state.loading = false;
      state.data = action.payload.Currencies;
      state.defaultCurrency = action.payload.BaseCurrency;
      state.currentCurrency = action.payload.userCurrency ?? action.payload.BaseCurrency;
      if (action.payload.userCountry != undefined) {
        state.currencyCountry = action.payload.userCountry;
        state.currencyCountryName = action.payload.currentCountryName;
      }

      state.displayCountryList = action.payload.displayCountryList;
    },
    [getCurrencyinfo.pending]: (state) => {
      state.loading = true;
    },
    [getCurrencyinfo.rejected]: (state) => {
      state.loading = false;
      state.error = "Resource Key Error";
    },
  },
});

export { getCurrencyinfo };

export const currencyActions = currencySlice.actions;

export default currencySlice.reducer;
